import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { css } from '@emotion/core'
import Container from 'components/Container'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Link from '../components/Link'
import { bpMaxSM, bpMaxMD } from '../lib/breakpoints'

const Blog = ({
  data: { site, allMdx },
  pageContext: { pagination, categories },
}) => {
  const { page, nextPagePath, previousPagePath } = pagination

  const posts = page
    .map(id => allMdx.edges.find(edge => edge.node.id === id))
    .filter(post => post !== undefined)
  console.log('posts', posts)
  return (
    <Layout site={site}>
      <SEO />
      <Container noVerticalPadding>
        {posts
          .filter(({ node: post }) => new Date(post.fields.date) <= new Date())
          .map(({ node: post }) => (
            <div
              key={post.id}
              css={css`
                :not(:first-of-type) {
                  margin-top: 60px;
                  ${bpMaxMD} {
                    margin-top: 40px;
                  }
                  ${bpMaxSM} {
                    margin-top: 20px;
                  }
                }
                :first-of-type {
                  margin-top: 20px;
                  ${bpMaxSM} {
                    margin-top: 20px;
                  }
                }
                .gatsby-image-wrapper {
                }
                ${bpMaxSM} {
                  padding: 20px;
                }
                display: flex;
                flex-direction: column;
              `}
            >
              {post.frontmatter.banner && (
                <div
                  css={css`
                    padding: 60px 60px 40px 60px;
                    ${bpMaxSM} {
                      padding: 20px;
                    }
                  `}
                >
                  <Link
                    aria-label={`View ${post.frontmatter.title} article`}
                    to={`/${post.fields.slug}`}
                  >
                    <Img
                      sizes={post.frontmatter.banner.childImageSharp.fluid}
                    />
                  </Link>
                  <span>
                    Photo by{' '}
                    <a href={post.frontmatter.bannerByLink}>
                      {post.frontmatter.bannerBy}
                    </a>{' '}
                    on{' '}
                    <a href={post.frontmatter.bannerOnLink}>
                      {post.frontmatter.bannerOn}
                    </a>
                  </span>
                </div>
              )}
              <h2
                css={css`
                  margin-top: 30px;
                  margin-bottom: 10px;
                `}
              >
                <Link
                  aria-label={`View ${post.frontmatter.title} article`}
                  to={`/${post.fields.slug}`}
                >
                  {post.frontmatter.title}
                </Link>
              </h2>
              {/* <small>{post.frontmatter.date}</small> */}
              <p
                css={css`
                  margin-top: 10px;
                `}
              >
                {post.excerpt}
              </p>{' '}
              <Link
                to={`/${post.fields.slug}`}
                aria-label={`view "${post.frontmatter.title}" article`}
              >
                Read Article →
              </Link>
            </div>
          ))}
        <div css={css({ marginTop: '30px' })}>
          {nextPagePath && (
            <Link to={nextPagePath} aria-label="View next page">
              Next Page →
            </Link>
          )}
          {previousPagePath && (
            <Link to={previousPagePath} aria-label="View previous page">
              ← Previous Page
            </Link>
          )}
        </div>
        <hr
          css={css`
            margin: 50px 0;
          `}
        />
      </Container>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      ...site
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { isPost: { eq: true } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          id
          fields {
            title
            slug
            date
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            banner {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            bannerOn
            bannerBy
            bannerOnLink
            bannerByLink
            slug
            keywords
          }
        }
      }
    }
  }
`
